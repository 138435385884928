import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faCheckSquare from '@fortawesome/fontawesome-pro-light/faCheckSquare';
import faSquare from '@fortawesome/fontawesome-pro-light/faSquare';
import faExternalLink from '@fortawesome/fontawesome-pro-light/faExternalLink';
import './Term.css';

const Term = ({
  index,
  startTerm,
  startYear,
  concentration,
  type,
  delivery,
  applicationType,
  deadline,
  sophasStatus,
  handleTermClick,
  degreeIndex,
  organizationIndex,
}) => (
  <tr
    className="Term--row"
    onClick={() => handleTermClick(index, degreeIndex, organizationIndex)}
    data-id={index}
  >
    <td>
      <a>
        {startTerm} {startYear} <FontAwesomeIcon icon={faExternalLink} />
      </a>
    </td>
    <td>{concentration}</td>
    <td>{delivery}</td>
    <td>{applicationType}</td>
    <td>{deadline}</td>
    <td>
      {sophasStatus !== 'non_sophas' ? (
        <FontAwesomeIcon icon={faCheckSquare} />
      ) : (
        <FontAwesomeIcon icon={faSquare} />
      )}
    </td>
  </tr>
);

export default Term;
