/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
/* global window */
import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import ReactGA from "react-ga4";
import queryString from 'query-string';
import OrganizationList from '../components/OrganizationList/OrganizationList';
import Header from '../components/Header/Header';
import SubHeader from '../components/SubHeader/SubHeader';
import SortBar from '../components/SortBar/SortBar';
import Modal from '../components/Modal/Modal';
import SearchForm from '../components/SearchForm/SearchForm';
import cache from '../Cache';
import Sidebar from '../components/Sidebar/Sidebar';
import MobileSidebar from '../components/MobileSidebar/MobileSidebar';
import Footer from '../components/Footer/Footer';
import MobileSubFooter from '../components/MobileSubFooter/MobileSubFooter';
import Hero from '../components/Hero/Hero';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import Program from '../components/Program/Program';
import MaintenanceMessage from '../components/MaintenanceMessage/MaintenanceMessage';
import { Routes, Route } from 'react-router-dom';
import { withRouter } from '../components/WithRouter/withRouter.js';

class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      isUnderMaintenance: false,
      sortPreference: 'asc',
      searching: false,
      modalIsOpen: false,
      programDescription: {
        organization: {},
        degree: {},
        term: cache.getProgramInfo() || {},
        content: '',
        id: '',
      },
      searched: false,
      locationSearched: false,
      formAreasOfStudy: cache.getAreasOfStudy() || [{ name: 'All', header: true, checked: true }],
      formInstitutions: cache.getInstitutions() || [
        { id: 1, name: 'All', header: true, checked: false },
        { id: 2, name: 'CEPH Accredited', justify: true, header: true, checked: false },
        { id: 3, name: 'CEPH Applicant', justify: true, header: true, checked: false },
      ],
      formFilterOptions: [],
      formFilterCephAccredited: false,
      formFilterCephApplicant: false,
      formProgramAreaKeywords: '',
      formLocationKeywords: '',
      formLocationDistance: 250,
      formLocationMethod: 'city/zip',
      formLocationState: '',
      formStateOptions: [],
      formLocationCountry: "",
      formCountryOptions: [],
      homeHeroProps: {
        title: 'Academic Program Finder',
        links: [
          { text: 'Home', url: 'https://www.aspph.org' },
          { text: 'Student Journey', url: 'https://aspph.org/student-journey/' },
        ],
        text: 'Your decisions when pursuing a graduate degree can have a big impact on your future in the field. Our Academic Program Finder helps guide you in finding the right school or program that aligns with your aspirations. Embrace the possibilities that our tool offers as you embark on the promising journey that lies ahead.',
        button: { text: 'Explore Programs', anchor: '#searchForm' },
      },
      // Initialize the home route in state object
      route: window.location.pathname,
      selectedApplicantType: null,
    };

    this.closeModal = this.closeModal.bind(this);
    this.fetchSearch = this.fetchSearch.bind(this);
    this.formParams = this.formParams.bind(this);
    this.handleAreasOfStudyCheckBoxClick = this.handleAreasOfStudyCheckBoxClick.bind(this);
    this.handleClearFiltersClick = this.handleClearFiltersClick.bind(this);
    this.handleDegreeClick = this.handleDegreeClick.bind(this);
    this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
    this.handleFilterCheck = this.handleFilterCheck.bind(this);
    this.handleApplicantTypeChange = this.handleApplicantTypeChange.bind(this);
    this.handleInstitutionCheckBoxClick = this.handleInstitutionCheckBoxClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleLocationDistanceChange = this.handleLocationDistanceChange.bind(this);
    this.handleLocationKeywordsChange = this.handleLocationKeywordsChange.bind(this);
    this.handleLocationStateChange = this.handleLocationStateChange.bind(this);
    this.handleLocationCountryChange = this.handleLocationCountryChange.bind(this);
    this.handleLocationMethodChange = this.handleLocationMethodChange.bind(this);
    this.handleOrganizationClick = this.handleOrganizationClick.bind(this);
    this.handleProgramAreaKeywordsChange = this.handleProgramAreaKeywordsChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleSortPreferenceChange = this.handleSortPreferenceChange.bind(this);
    this.handleTermClick = this.handleTermClick.bind(this);
    this.updateStateFromQueryParams = this.updateStateFromQueryParams.bind(this);
    this.handleDualDegrees = this.handleDualDegrees.bind(this);
    this.handleDrPhd = this.handleDrPhd.bind(this);
    this.handleEnvironmentalSearch = this.handleEnvironmentalSearch.bind(this);
    this.handlePreventativeSearch = this.handlePreventativeSearch.bind(this);
    this.scrollToResults = this.scrollToResults.bind(this);
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.setState({
        ...this.state,
        route: window.location.pathname,
      });
    };
    window.document.addEventListener('keydown', this.handleKeyPress, false);

    const googleAnalyticsTag = process.env.REACT_APP_GA_TAG;

    if (googleAnalyticsTag) {
      ReactGA.initialize(googleAnalyticsTag);
    }

    this.fetchInitialData()

    this.setupLocationFiltersFromApi();
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  fetchInitialData() {
    const apiUrl = process.env.REACT_APP_API_URL;
    axios
      .get(`${apiUrl}/v3/setup`)
      .then((res) => {
        this.setupInitialStateFromApi(res.data);
      })
      .catch((error) => {
        console.error("Error fetching initial data:", error);
      })
      .finally(() => {
        if (Object.entries(this.queryParams()).length > 0) {
          this.updateStateFromQueryParams();
        }
      });
  }

  scrollToResults() {
    const target = document.getElementById('OrganizationList');
    if (target) {
      const offsetTop = target.getBoundingClientRect().top;
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      const finalScrollPosition = offsetTop + scrollPosition - 150;

      window.scrollTo({
        top: finalScrollPosition,
        behavior: 'smooth',
      });
    }
  }

  handleDualDegrees() {
    const degreeFilters = [...this.state.formFilterOptions];
    const areasOfStudy = [...this.state.formAreasOfStudy];

    // areasOfStudy.forEach((area) => {
    //   area.checked = false;
    // });
    // degreeFilters.forEach((filter) => {
    //   filter.checked = false;
    // });
    degreeFilters[0].active = false;
    degreeFilters[3].active = true;
    // degreeCount = 0;
    for (let i = 7; i < 18; i++) {
      if (i === 9 || i === 14 || i === 15) {
        continue;
      }
      degreeFilters[3].filters[i].checked = true;
    }

    this.setState(
      {
        formFilterOptions: degreeFilters,
      },
      () => {
        window.history.pushState(
          this.formParams(),
          null,
          `?${queryString.stringify(this.formParams(), { arrayFormat: 'comma' })}`
        );
        this.fetchSearch();
        this.scrollToResults();
      }
    );
  }

  handleDrPhd() {
    const degreeFilters = [...this.state.formFilterOptions];
    degreeFilters[0].active = false;
    degreeFilters[3].active = true;
    degreeFilters[3].filters[1].checked = true;
    degreeFilters[3].filters[15].checked = true;
    degreeFilters[3].filters[19].checked = true;

    this.setState(
      {
        formFilterOptions: degreeFilters,
      },
      () => {
        window.history.pushState(
          this.formParams(),
          null,
          `?${queryString.stringify(this.formParams(), { arrayFormat: 'comma' })}`
        );

        this.fetchSearch();
        this.scrollToResults();
      }
    );
  }

  handleEnvironmentalSearch() {
    const areasOfStudy = [...this.state.formAreasOfStudy];
    areasOfStudy[2].checked = true;
    areasOfStudy[7].checked = true;
    areasOfStudy[17].checked = true;
    areasOfStudy[20].checked = true;
    areasOfStudy[31].checked = true;
    areasOfStudy[44].checked = true;
    areasOfStudy[45].checked = true;
    areasOfStudy[62].checked = true;
    this.setState(
      {
        formAreasOfStudy: areasOfStudy,
      },
      () => {
        window.history.pushState(
          this.formParams(),
          null,
          `?${queryString.stringify(this.formParams(), { arrayFormat: 'comma' })}`
        );

        this.fetchSearch();
        this.scrollToResults();
      }
    );
  }



  handlePreventativeSearch() {
    const areasOfStudy = [...this.state.formAreasOfStudy];
    areasOfStudy[16].checked = true;
    areasOfStudy[32].checked = true;
    areasOfStudy[33].checked = true;
    areasOfStudy[35].checked = true;

    this.setState(
      {
        formAreasOfStudy: areasOfStudy,
      },
      () => {
        window.history.pushState(
          this.formParams(),
          null,
          `?${queryString.stringify(this.formParams(), { arrayFormat: 'comma' })}`
        );

        this.fetchSearch();
        this.scrollToResults();
      }
    );
  }

  navigate(route, params) {
    // handles navigation logic by updating state with the new route

    window.history.pushState(params, '', route);
    this.setState((prevState) => ({
      ...prevState,
      route: window.location.pathname,
      params: params,
    }));
  }

  updateStateFromQueryParams() {
    // eslint-disable-next-line prefer-const
    let {
      formAreasOfStudy,
      formInstitutions,
      formFilterOptions,
      formFilterCephAccredited,
      formFilterCephApplicant,
      formProgramAreaKeywords,
      formLocationKeywords,
      formLocationDistance,
      formLocationMethod,
      formLocationState,
    } = this.state;

    // formAreasOfStudy
    const queryProgramAreas = this.queryParams().programAreas;
    if (queryProgramAreas) {
      formAreasOfStudy.forEach((areaOfStudy, index) => {
        if (index < 1) {
          areaOfStudy.checked = false;
        }
        if (queryProgramAreas.includes(areaOfStudy.name)) {
          areaOfStudy.checked = true;
        }
      });
    }

    // formInstitutions
    let querySalesforceIds = this.queryParams().institutions;
    if (querySalesforceIds) {
      querySalesforceIds =
        typeof querySalesforceIds === 'string' ? [querySalesforceIds] : querySalesforceIds;
      const queryInstitutions = _.compact(
        querySalesforceIds
          .map((salesforceId) => _.find(formInstitutions, (i) => i.salesforce_id === salesforceId))
          .map((i) => (i ? i.name : null))
      );

      formInstitutions.forEach((institution, index) => {
        if (index < 3) {
          institution.checked = false;
        }
        if (queryInstitutions.includes(institution.name)) {
          institution.checked = true;
        }
      });
    }

    const queryLocationAreaMethod = this.queryParams().locationMethod;
    if (queryLocationAreaMethod) {
      formLocationMethod = queryLocationAreaMethod;
    }

    const queryLocationState = this.queryParams().locationState;
    if (queryLocationState) {
      formLocationState = queryLocationState;
    }

    const queryProgramAreaKeywords = this.queryParams().programAreaKeywords;
    if (queryProgramAreaKeywords) {
      formProgramAreaKeywords = queryProgramAreaKeywords;
    }

    const queryLocationKeywords = this.queryParams().locationKeywords;
    if (queryLocationKeywords) {
      formLocationKeywords = queryLocationKeywords;
    }

    const queryLocationDistance = this.queryParams().locationDistance;
    if (queryLocationDistance) {
      formLocationDistance = queryLocationDistance;
    }

    // formFilterOptions, broken down to: delivery, stem, gre, degrees, startTerms, additionalFilters
    // 0 delivery
    const queryDelivery = this.queryParams().delivery;
    if (queryDelivery) {
      formFilterOptions[0].filters.forEach((option) => {
        if (queryDelivery.includes(option.name)) {
          option.checked = true;
        }
      });
    }

    // 1 stem
    const queryStem = this.queryParams().stemProgram;
    if (queryStem) {
      formFilterOptions[1].filters.forEach((option) => {
        if (queryStem.includes(option.name)) {
          option.checked = true;
        }
      });
    }

    // 2 gre
    const queryGre = this.queryParams().greRequirement;
    if (queryGre) {
      formFilterOptions[2].filters.forEach((option) => {
        if (queryGre.includes(option.name)) {
          option.checked = true;
        }
      });
    }

    // 3 degrees
    const queryDegrees = this.queryParams().degrees;
    if (queryDegrees) {
      formFilterOptions[3].filters.forEach((option) => {
        if (queryDegrees.includes(option.name)) {
          option.checked = true;
        }
      });
    }

    // 4 startTerms
    const queryStartTerms = this.queryParams().startTerms;
    if (queryStartTerms) {
      formFilterOptions[4].filters.forEach((option) => {
        if (queryStartTerms.includes(option.name)) {
          option.checked = true;
        }
      });
    }

    // 5 additionalFilters
    const queryAdditionalFilters = this.queryParams().additionalFilters;
    if (queryAdditionalFilters) {
      formFilterOptions[5].filters.forEach((option) => {
        if (queryAdditionalFilters.includes(option.name)) {
          option.checked = true;
        }
      });
    }


    // Update state
    this.setState(
      {
        ...this.state,
        formAreasOfStudy,
        formInstitutions,
        formFilterOptions,
        formProgramAreaKeywords,
        formLocationKeywords,
        formLocationDistance,
        formLocationMethod,
        formLocationState,
      },
      () => {
        this.fetchSearch();
      }
    );
  }

  queryParams() {
    return queryString.parse(window.location.search, { arrayFormat: 'comma' });
  }

  setupStateFromQueryParams() {
    this.setState();
  }

  setupInitialStateFromApi(data) {
    const organizations = this.setupOrganizationsFromApi(data.institutions);
    const formAreasOfStudy = [
      { name: 'All', header: true, checked: true },
      ...data.areas_of_study.map((areaName) => ({
        name: areaName,
        checked: false,
      })),
    ];
    cache.setAreasOfStudy(formAreasOfStudy);

    const formInstitutions = [
      { id: 1, name: 'All', header: true, checked: true },
      { id: 2, name: 'CEPH Accredited', justify: true, header: true, checked: false },
      { id: 3, name: 'CEPH Applicant', justify: true, header: true, checked: false },
      ...organizations,
    ];

    cache.setInstitutions(formInstitutions);
    cache.setHeaderCounts(formInstitutions);

    const formFilterOptions = [
      {
        name: 'Delivery Method',
        active: true,
        filters: [
          { name: 'On-Campus', checked: false },
          { name: 'Online', checked: false },
          { name: 'Online/On-Campus Hybrid', checked: false },
        ],
      },
      {
        name: 'STEM',
        active: false,
        filters: [{ name: 'STEM Designated Programs', checked: false }],
      },
      {
        name: 'GRE',
        active: false,
        filters: [
          { name: 'Required', checked: false },
          { name: 'Optional', checked: false },
          { name: 'Not Required/Not Reviewed', checked: false },
        ],
      },
      {
        name: 'Degree',
        active: false,
        filters: data.degrees.map((degreeName) => ({
          name: degreeName,
          checked: false,
        })),
      },
      {
        name: 'Start Term',
        active: false,
        filters: data.start_terms.map((termName) => ({
          name: termName,
          checked: false,
        })),
      },
      {
        name: 'Additional Filters',
        active: false,
        filters: [
          { name: 'Currently Accepting Applications', checked: false },
          {
            name: 'SOPHAS-only [<a href="https://sophas.aspph.org" target="_blank">learn more</a>]',
            checked: false,
          },
          { name: 'Summer Program', checked: false }
        ],
      },
    ];

    this.setState({ organizations, formAreasOfStudy, formInstitutions, formFilterOptions, searched: false });
  }

  /* eslint-disable no-param-reassign */
  /* eslint-disable class-methods-use-this */
  setupOrganizationsFromApi(institutions) {
    const termOrder = {
      Winter: 1,
      Spring: 2,
      Summer: 3,
      Fall: 4,
      Interim: 5,
    };
    const newInstitutions = institutions.map((i, index, innies) => {
      // Rails API distance is not working, but it is ordering according to distance, so just use index
      if (innies.length === 1) {
        i.degreesShown = true;
      }
      i.distance = index;
      i.degrees = _(i.programs)
        .groupBy((ins) => ins.name)
        .map((terms, degreeName) => {
          const newTerms = _.sortBy(terms, (t) => [t.start_year, termOrder[t.start_term]]);
          return { name: degreeName, terms: newTerms, termsShown: false };
        })
        .value();
      i.checked = false;
      // i.id = ;
      return i;
    });

    return newInstitutions;
  }

  setupLocationFiltersFromApi() {
    let chinaStates = [];
    let usaStates = [];
    let mexicoStates = [];
    let sortedCountries = [];
    sortedCountries.push('United States');

    const apiUrl = process.env.REACT_APP_API_URL;

    axios.get(`${apiUrl}/v3/setup`).then((res) => {
      res.data.member_countries.forEach((memberCountry) => {
        if (memberCountry === 'United States') {
          return;
        } else {
          sortedCountries.push(memberCountry);
        }
      });
      res.data.member_states.forEach((memberState) => {
        if (memberState === 'Hong Kong') {
          chinaStates.push(memberState);
        } else if (memberState === 'Morelos') {
          mexicoStates.push(memberState);
        } else {
          usaStates.push(memberState);
        }
      });

      const combinedStates = [
        { label: '', isDisabled: true },
        {
          label: 'United States',
          isDisabled: true,
          style: { fontWeight: 700, textDecoration: 'underline' },
        },
        ...usaStates.map((city) => ({ label: city, value: city })),
        {
          label: 'China',
          isDisabled: true,
          style: { fontWeight: 700, textDecoration: 'underline' },
        },
        ...chinaStates.map((city) => ({ label: city, value: city })),
        {
          label: 'Mexico',
          isDisabled: true,
          style: { fontWeight: 700, textDecoration: 'underline' },
        },
        ...mexicoStates.map((city) => ({ label: city, value: city })),
      ];

      this.setState({
        formStateOptions: combinedStates,
        formCountryOptions: sortedCountries,
      });
    });
  }

  fetchSearch() {
    this.setState({ searching: true });
    const formParams = this.formParams();
    const locationSearched = formParams.locationKeywords !== '';
    const apiUrl = process.env.REACT_APP_API_URL;

    if (!_.isEmpty(formParams)) {
      ReactGA.event({
        category: 'Search Event',
        action: 'Search',
        label: JSON.stringify(formParams.programAreaKeywords),
      });
    }

    axios
      .post(`${apiUrl}/v3/organizations/search`, formParams)
      .then((res) => {
        this.setState({
          searching: false,
          searched: true,
          // sortPreference: locationSearched ? 'asc' : 'institution',
          // locationSearched,
          organizations: this.setupOrganizationsFromApi(res.data),
        })
      })
  }

  handleSortPreferenceChange(selectedOption) {
    this.setState({ sortPreference: selectedOption });

    if (selectedOption.value === 'asc') {
      this.setState({ organizations: _.orderBy(this.state.organizations, 'name', 'asc') });
    } else if (selectedOption.value === 'desc') {
      this.setState({ organizations: _.orderBy(this.state.organizations, 'name', 'desc') });
    }

    // if (selectedOption.label.toLowerCase().includes('deadline')) {
    //   const sortOrder = selectedOption.value === 'deadline-asc' ? 'asc' : 'desc';

    //   const sortedOrganizations = this.state.organizations.map((org) => {
    //     const sortedPrograms = org.programs.map((program) => {
    //       const [validTerms, invalidTerms] = _.partition(program.terms, (term) => {
    //         const date = new Date(term.deadline_display);
    //         return !isNaN(date.getTime());
    //       });

    //       const sortedValidTerms = _.orderBy(validTerms, (term) => new Date(term.deadline_display), sortOrder);

    //       return {
    //         ...program,
    //         terms: [...sortedValidTerms, ...invalidTerms],
    //       };
    //     });

    //     const sortedValidPrograms = _.orderBy(sortedPrograms, (program) => {
    //       const earliestTerm = _.minBy(program.terms, (term) => {
    //         const date = new Date(term.deadline_display);
    //         return isNaN(date.getTime()) ? Infinity : date;
    //       });
    //       return earliestTerm ? new Date(earliestTerm.deadline_display) : Infinity;
    //     }, sortOrder);

    //     return {
    //       ...org,
    //       programs: sortedValidPrograms,
    //     };
    //   });

    //   const sortedByDeadlineOrganizations = _.orderBy(sortedOrganizations, (org) => {
    //     const earliestProgram = _.minBy(org.programs, (program) => {
    //       const earliestTerm = _.minBy(program.terms, (term) => {
    //         const date = new Date(term.deadline_display);
    //         return isNaN(date.getTime()) ? Infinity : date;
    //       });
    //       return earliestTerm ? new Date(earliestTerm.deadline_display) : Infinity;
    //     });
    //     return earliestProgram ? new Date(earliestProgram.deadline_display) : Infinity;
    //   }, sortOrder);

    //   this.setState({ organizations: sortedByDeadlineOrganizations }, () => {
    //     console.log("State Updated:", this.state.organizations);
    //   });
    // }

  }

  // else if (selectedOption.value === 'location') {
  //   this.setState({
  //     organizations: _.orderBy(this.state.organizations, ['distance', 'name'], ['asc', 'asc']),
  //   });
  // }

  handleOrganizationClick(index) {
    const { organizations } = this.state;

    organizations[index] = {
      ...organizations[index],
      degreesShown: !organizations[index].degreesShown,
    };

    this.setState({ organizations });

    ReactGA.event({
      category: 'Click',
      action: 'Organization Click To Expand',
      label: organizations[index].name,
    });
  }

  handleDegreeClick(index, organizationIndex) {
    const { organizations } = this.state;

    ReactGA.event({
      category: 'Click',
      action: 'Degree Click To Expand',
      label: `${organizations[organizationIndex].name} | ${organizations[organizationIndex].degrees[index].name}`,
    });

    organizations[organizationIndex].degrees[index] = {
      ...organizations[organizationIndex].degrees[index],
      termsShown: !organizations[organizationIndex].degrees[index].termsShown,
    };

    this.setState({ organizations });
  }

  handleTermClick(index, degreeIndex, organizationIndex) {
    const { programDescription, organizations } = this.state;

    ReactGA.event({
      category: 'Program Term Click',
      action: 'Click',
      label: `${programDescription.organization.name} | ${programDescription.term.name}`,
    });

    programDescription.organization = organizations[organizationIndex];
    programDescription.degree = organizations[organizationIndex].degrees[degreeIndex];
    programDescription.term = organizations[organizationIndex].degrees[degreeIndex].terms[index];
    // Need to send API request now

    let programId = '';
    let sophas_status = '';

    if (
      programDescription.term.sophas_status === 'sophas' ||
      programDescription.term.sophas_status === 'sophas_express'
    ) {
      programId = programDescription.term.sophas_id;
      sophas_status = 'sophas';
    } else if (programDescription.term.sophas_status === 'non_sophas') {
      programId = programDescription.term.non_sophas_id;
      sophas_status = 'non_sophas';
    }

    cache.setProgramInfo(programDescription.term);

    const apiUrl = process.env.REACT_APP_API_URL;

    axios
      .get(`${apiUrl}/v3/programs/${programId}?sophas_status=${sophas_status}`)

      .then((res) => {
        this.updateProgramDescription(programDescription, res.data);
      })

      .then(() => {
        const finalURL = `/?program_id=${programId}&sophas_status=${sophas_status}`

        window.open(finalURL, "_blank");
      });
  }

  updateProgramDescription(programDescription, program) {
    programDescription.term.description = program.description;
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  // ALL NEW FORM STUFF MOVED FROM FORM CONTAINER

  // Left off: very beginning of this, need to refactor
  handleInstitutionCheckBoxClick(index, e) {
    const { formInstitutions } = this.state;
    formInstitutions[index].checked = !formInstitutions[index].checked;

    if (e.target.name !== 'All') {
      formInstitutions[0].checked = false;
    }

    if (e.target.name === 'CEPH Accredited') {
      // If Applicant & Accredited are selected, switch to All
      if (this.state.formFilterCephApplicant && !this.state.formFilterCephAccredited) {
        formInstitutions.forEach((_value, i) => {
          if (formInstitutions[i].name === 'All') {
            formInstitutions[i].checked = true;
          } else {
            formInstitutions[i].checked = false;
          }
        });

        this.setState({
          formFilterCephAccredited: false,
          formFilterCephApplicant: false,
          formInstitutions,
        });
        return;
      }
      this.setState({ formFilterCephAccredited: !this.state.formFilterCephAccredited });

      if (!this.state.formFilterCephAccredited === true) {
        formInstitutions.forEach((value, i) => {
          if (formInstitutions[i].header !== true) {
            formInstitutions[i].checked = formInstitutions[i].accredited;
          }
        });
      } else {
        formInstitutions[0].checked = true;
        formInstitutions.forEach((value, i) => {
          if (formInstitutions[i].header !== true && formInstitutions[i].accredited) {
            formInstitutions[i].checked = !formInstitutions[i].accredited;
          }
        });
      }
    }

    if (e.target.name === 'CEPH Applicant') {
      // If Applicant & Accredited are selected, switch to All
      if (!this.state.formFilterCephApplicant && this.state.formFilterCephAccredited) {
        formInstitutions.forEach((_value, i) => {
          if (formInstitutions[i].name === 'All') {
            formInstitutions[i].checked = true;
          } else {
            formInstitutions[i].checked = false;
          }
        });

        this.setState({
          formFilterCephAccredited: false,
          formFilterCephApplicant: false,
          formInstitutions,
        });
        return;
      }

      if (!this.state.formFilterCephApplicant === true) {
        formInstitutions.forEach((_value, i) => {
          if (formInstitutions[i].header !== true) {
            formInstitutions[i].checked = !formInstitutions[i].accredited;
          }
        });
      } else {
        formInstitutions[0].checked = true;
        formInstitutions.forEach((_value, i) => {
          if (formInstitutions[i].header !== true && !formInstitutions[i].accredited) {
            formInstitutions[i].checked = formInstitutions[i].accredited;
          }
        });
      }
      this.setState({ formFilterCephApplicant: !this.state.formFilterCephApplicant });
    }

    if (e.target.name === 'All') {
      formInstitutions.forEach((value, i) => {
        if (value.name !== 'All') {
          formInstitutions[i].checked = false;
        }
      });
      this.setState({ formFilterCephAccredited: false, formFilterCephApplicant: false });
    }

    if (!_.find(formInstitutions, (institution) => institution.checked)) {
      formInstitutions[0].checked = true;
    }

    this.setState({ formInstitutions });
  }

  handleAreasOfStudyCheckBoxClick(index, e) {
    const { formAreasOfStudy } = this.state;
    formAreasOfStudy[index].checked = !formAreasOfStudy[index].checked;

    if (e.target.name === 'All') {
      formAreasOfStudy.forEach((_value, i) => {
        if (formAreasOfStudy[i].name !== 'All') {
          formAreasOfStudy[i].checked = false;
        }
      });
    }

    if (e.target.name !== 'All') {
      formAreasOfStudy[0].checked = false;
    }

    if (!_.find(formAreasOfStudy, (programArea) => programArea.checked)) {
      formAreasOfStudy[0].checked = true;
    }

    this.setState({ formAreasOfStudy });
  }

  handleProgramAreaKeywordsChange(e) {
    this.setState({ formProgramAreaKeywords: e.target.value });
  }

  handleLocationKeywordsChange(e) {
    this.setState({ formLocationKeywords: e.target.value });
  }

  handleLocationStateChange(selectOption) {
    this.setState({ formLocationState: selectOption.value });
  }

  handleLocationCountryChange(selectOption) {
    this.setState({
      formLocationCountry: selectOption.value,
    });
  }

  handleLocationDistanceChange(selectOption) {
    this.setState({ formLocationDistance: selectOption.value });
  }

  handleLocationMethodChange = (e) => {
    const updates = { formLocationMethod: e.target.value };

    switch (e.target.value) {
      case 'city/zip':
        Object.assign(updates, { formLocationState: '', formLocationCountry: '', formLocationDistance: 250 });
        break;
      case 'state/province':
        Object.assign(updates, { formLocationCountry: '', formLocationKeywords: '', formLocationDistance: '' });
        break;
      case 'country':
        Object.assign(updates, { formLocationState: '', formLocationKeywords: '', formLocationDistance: '' });
        break;
      default:
        break;
    }
    this.setState(updates);
  };


  handleFilterButtonClick(index, e) {
    e.preventDefault();
    const { formFilterOptions } = this.state;

    formFilterOptions.forEach((option, i) =>
      i === index ? (option.active = !option.active) : (option.active = false)
    );

    this.setState({ formFilterOptions });
  }

  handleFilterCheck(optionIndex, filterIndex, e) {
    const { formFilterOptions } = this.state;

    const inputChecked = formFilterOptions[optionIndex].filters[filterIndex];
    const sophasOnly = formFilterOptions[5].filters[1];
    const summerProgram = formFilterOptions[5].filters[2];

    inputChecked.checked = !inputChecked.checked;

    if (inputChecked === sophasOnly) {
      if (inputChecked.checked && summerProgram.checked) {
        summerProgram.checked = !summerProgram.checked;
      }
    }

    if (inputChecked === summerProgram) {
      if (inputChecked.checked && sophasOnly.checked) {
        sophasOnly.checked = !sophasOnly.checked;
      }
    }

    // Sophas check

    this.setState({ formFilterOptions });
  }

  handleApplicantTypeChange(selectedOption) {
    this.setState({ selectedApplicantType: selectedOption });
  }

  handleClearFiltersClick(e) {
    e.preventDefault();
    this.setState(
      {
        formInstitutions: this.state.formInstitutions.map((i) => {
          if (i.name === 'All') {
            i.checked = true;
          } else {
            i.checked = false;
          }
          return i;
        }),
        formAreasOfStudy: this.state.formAreasOfStudy.map((i) => {
          if (i.name === 'All') {
            i.checked = true;
          } else {
            i.checked = false;
          }
          return i;
        }),
        formProgramAreaKeywords: '',
        formLocationKeywords: '',
        selectedApplicantType: null,
        formLocationMethod: 'city/zip',
        formLocationDistance: 250,
        formLocationState: '',
        formLocationCountry: '',
        formFilterCephAccredited: false,
        formFilterCephApplicant: false,
        formFilterOptions: this.state.formFilterOptions.map((o) => {
          o.filters = o.filters.map((f) => {
            f.checked = false;
            return f;
          });
          return o;
        }),
        sortPreference: 'asc',
      },
      () => {
        window.history.pushState(null, null, '/');
        this.fetchInitialData();
      }
    );
  }


  handleKeyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.fetchSearch();
    }
  }

  formParams() {
    const {
      formAreasOfStudy,
      formInstitutions,
      formProgramAreaKeywords,
      formLocationDistance,
      formLocationKeywords,
      formLocationState,
      formLocationCountry,
      formFilterOptions,
      formLocationMethod,
      selectedApplicantType,
    } = this.state;

    const programAreas = formAreasOfStudy
      .filter((p) => p.checked && p.name !== 'All')
      .map((p) => p.name);
    const institutions = formInstitutions
      .filter((i) => i.checked && i.name !== 'All')
      .map((i) => i.salesforce_id);

    return {
      programAreas,
      institutions,
      programAreaKeywords: formProgramAreaKeywords,
      locationKeywords: formLocationKeywords,
      locationDistance: formLocationDistance,
      locationState: formLocationState,
      locationCountry: formLocationCountry,
      locationMethod: formLocationMethod,
      delivery: formFilterOptions[0].filters.filter((f) => f.checked).map((f) => f.name),
      stemProgram: formFilterOptions[1].filters.filter((s) => s.checked).map((s) => s.name),
      greRequirement: formFilterOptions[2].filters.filter((g) => g.checked).map((g) => g.name),
      degrees: formFilterOptions[3].filters.filter((d) => d.checked).map((d) => d.name),
      startTerms: formFilterOptions[4].filters.filter((t) => t.checked).map((t) => t.name),
      additionalFilters: formFilterOptions[5].filters.filter((a) => a.checked).map((a) => a.name),
      applicantType: selectedApplicantType ? selectedApplicantType.value : null
    };
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    // Maybe here update query params?
    window.history.pushState(
      this.formParams(),
      null,
      `?${queryString.stringify(this.formParams(), { arrayFormat: 'comma' })}`
    );

    this.fetchSearch();
  }

  render() {
    const {
      sortPreference,
      modalIsOpen,
      programDescription,
      searching,
      formAreasOfStudy,
      formInstitutions,
      formFilterOptions,
      formProgramAreaKeywords,
      locationSearched,
      formLocationKeywords,
      formLocationState,
      formLocationCountry,
      formLocationDistance,
      formLocationMethod,
      formCountryOptions,
      formStateOptions,
    } = this.state;
    let vars = queryString.parse(window.location.search, { arrayFormat: 'comma' });
    return (
      <div>
        <Header />
        {!(vars.program_id && vars.sophas_status) && (
          <>
            <Hero heroProps={this.state.homeHeroProps} />
            {/* {this.state.isUnderMaintenance && <MaintenanceMessage />} */}
            <Jumbotron
              handleDualDegrees={this.handleDualDegrees}
              handleDrPhd={this.handleDrPhd}
              handleEnvironmentalSearch={this.handleEnvironmentalSearch}
              handlePreventativeSearch={this.handlePreventativeSearch}
            />
            <div className="container" id="mainContent">
              <main>
                <SubHeader />
                <SearchForm
                  areasOfStudy={formAreasOfStudy}
                  filterCephAccredited={this.state.formFilterCephAccredited}
                  filterCephApplicant={this.state.formFilterCephApplicant}
                  filterOptions={formFilterOptions}
                  handleAreasOfStudyCheckBoxClick={this.handleAreasOfStudyCheckBoxClick}
                  handleClearFiltersClick={this.handleClearFiltersClick}
                  handleFilterButtonClick={this.handleFilterButtonClick}
                  handleFilterCheck={this.handleFilterCheck}
                  handleApplicantTypeChange={this.handleApplicantTypeChange}
                  selectedApplicantType={this.state.selectedApplicantType}
                  handleInstitutionCheckBoxClick={this.handleInstitutionCheckBoxClick}
                  handleLocationDistanceChange={this.handleLocationDistanceChange}
                  handleLocationMethodChange={this.handleLocationMethodChange}
                  handleLocationKeywordsChange={this.handleLocationKeywordsChange}
                  handleLocationStateChange={this.handleLocationStateChange}
                  handleLocationCountryChange={this.handleLocationCountryChange}
                  handleProgramAreaKeywordsChange={this.handleProgramAreaKeywordsChange}
                  handleSearchSubmit={this.handleSearchSubmit}
                  institutionHeaderCount={cache.getHeaderCounts() || [0, 0, 0]}
                  institutions={formInstitutions}
                  locationDistance={formLocationDistance}
                  locationKeywords={formLocationKeywords}
                  locationState={formLocationState}
                  locationCountry={formLocationCountry}
                  locationMethod={formLocationMethod}
                  programAreaKeywords={formProgramAreaKeywords}
                  searching={searching}
                  stateOptions={formStateOptions}
                  countryOptions={formCountryOptions}
                />
                <SortBar
                  selectedSortPreference={sortPreference}
                  handleSortPreferenceChange={this.handleSortPreferenceChange}
                  locationSearched={locationSearched}
                  programsCount={_.flatMap(this.state.organizations, (org) => org.degrees).length}
                  institutionsCount={this.state.organizations.length}
                  searched={this.state.searched}
                />
                <OrganizationList
                  handleOrganizationClick={this.handleOrganizationClick}
                  organizations={this.state.organizations}
                  handleDegreeClick={this.handleDegreeClick}
                  handleTermClick={this.handleTermClick}
                  searched={this.state.searched}
                />
                {/* <Modal
                      isOpen={modalIsOpen}
                      programDescription={programDescription}
                      closeModal={this.closeModal}
                    /> */}
              </main>
              <aside>
                <Sidebar />
              </aside>
            </div>
          </>
        )}
        {vars.program_id && vars.sophas_status && (
          <Program programDescription={programDescription.term} />
        )}
        <Footer />
      </div>
    );
  }
}

export default withRouter(AppContainer);
