import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faAngleDown from '@fortawesome/fontawesome-pro-light/faAngleDown';
import faAngleUp from '@fortawesome/fontawesome-pro-light/faAngleUp';
import './Chevron.css';

const Chevron = ({ status }) => (
  <div className={`Chevron--container ${status}`}>
    {status === 'closed' ? (
      <FontAwesomeIcon key={3} icon={faAngleDown} />
    ) : (
      <FontAwesomeIcon key={2} icon={faAngleUp} />
    )}
  </div>
);

export default Chevron;
