import React from 'react';
import FlipMove from 'react-flip-move';
import Organization from '../Organization/Organization';
import './OrganizationList.css';

const OrganizationList = ({
  organizations,
  handleOrganizationClick,
  handleDegreeClick,
  handleTermClick,
  searched,
}) => (
  <div className="OrganizationList--container" id="OrganizationList">
    <FlipMove>
      {!organizations.length && searched && (
        <div className="OrganizationList--no-results">
          No results. Clear filters to widen search.
        </div>
      )}
      {organizations.map((organization, index) => (
        <div key={organization.name}>
          <Organization
            key={organization.name}
            index={index}
            organization={organization}
            handleOrganizationClick={handleOrganizationClick}
            handleDegreeClick={handleDegreeClick}
            handleTermClick={handleTermClick}
            loading={organization.loading}
          />
        </div>
      ))}
    </FlipMove>
  </div>
);

export default OrganizationList;
