import React from 'react';
import DegreeList from '../DegreeList/DegreeList';
import Chevron from '../Chevron/Chevron';
import Spinner from '../Spinner/Spinner';
import './Organization.css';

const Organization = ({
  organization,
  handleOrganizationClick,
  handleDegreeClick,
  handleTermClick,
  loading,
  index
}) => (
  <div className={`Organization--container ${organization.degreesShown ? 'open' : 'closed'}`}>
    <div role="button" className="Organization--bar" onClick={() => handleOrganizationClick(index)}>
      <div className="Organization--name">
        <a>{organization.name}</a>
      </div>
      <div className="Organization--location">{organization.location}</div>
      {loading && <Spinner />}
      {!loading && <Chevron status={`${organization.degreesShown ? 'open' : 'closed'}`} />}
    </div>
    {organization.degreesShown && (
      <DegreeList
        organizationIndex={index}
        degrees={organization.degrees}
        handleDegreeClick={handleDegreeClick}
        handleTermClick={handleTermClick}
        organizationId={organization.id}
        website={organization.website}
      />
    )}
  </div>
);

export default Organization;
