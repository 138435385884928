import React, { useEffect, useState } from 'react';
import './Program.css';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import cache from '../../Cache';
import Hero from '../Hero/Hero';
import ReactGA from 'react-ga4';


const Program = ({ name, route, programDescription }) => {
  const params = useParams();
  const [programDetails, setProgramDetails] = useState(cache.getProgramInfo() || {});
  const [location, setLocation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [historyParams, setHistoryParams] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      program_name: programDetails.name,
    });
  }, [programDetails]);

  const fetchTermData = (id, sophas_status) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      setIsLoading(true);

      axios
        .get(`${apiUrl}/v3/programs/${id}?sophas_status=${sophas_status}`)
        .then((res) => {
          setProgramDetails(res.data);
          const programLocation = res.data.city + ', ' + res.data.state;
          setLocation(programLocation);

          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  function showHowApply(sophasStatus) {
    if (sophasStatus === 'sophas') {
      return 'SOPHAS';
    } else if (sophasStatus === 'sophas_express') {
      return 'SOPHAS Express';
    } else if (sophasStatus === 'non_sophas') {
      return 'Contact Institution';
    }
    return '';
  }

  function showGreRequirement(gre) {
    if (gre === '' || gre === null) {
      return 'Contact Institution';
    } else {
      return gre;
    }
  }
  const sophasExpressLink = 'https://sophasexpress.liaisoncas.com/applicant-ux/#/login';
  const sophasLink = 'https://sophas.liaisoncas.com/applicant-ux/#/login';

  function buildLink(sophasStatus, website, term) {
    if (term.apply_url && term.apply_url !== '') {
      return term.apply_url;
    } else if (sophasStatus === 'sophas_express') {
      return sophasExpressLink;
    } else if (sophasStatus === 'sophas') {
      return sophasLink;
    }
    else {
      return website;
    }

    return '';
  }

  function showButtonText(sophasStatus) {

    if (sophasStatus === 'sophas' || sophasStatus === 'sophas_express') {
      return 'START YOUR APPLICATION';
    }
  }

  useEffect(() => {
    if (window.history.state.program_id && window.history.state.sophas_status) {
      fetchTermData(window.history.state.program_id, window.history.state.sophas_status);
    } else {
      fetchTermData(searchParams.get('program_id'), searchParams.get('sophas_status'));
    }
  }, []);

  const sophasState = programDetails.sophas_status === 'sophas' || programDetails.sophas_status === 'sophas_express'

  return (
    <>
      {isLoading === true ? (
        <></>
      ) : (
        <div>
          <Hero
            heroProps={{
              title: programDetails.organization_name,
              links: [
                { text: 'Home', url: 'https://www.aspph.org' },
                { text: 'Student Journey', url: 'https://aspph.org/student-journey/' },
                { text: 'Academic Program Finder', url: '/' },
              ],
              text: location,
            }}
          />
          <div className="program-container">
            <h3>{programDetails.name}</h3>
            <span className="Modal--stem-info">
              {programDetails.stem_program == 1 && `STEM Designated Program *`}
            </span>
            <div className="program-termstart">
              {programDetails.start_term} {programDetails.start_year} |{' '}
              {programDetails.apf_area_of_study}
            </div>
            <div className="program-deadline">Deadline: {programDetails.deadline_display}</div>

            <div className="Modal--info-box container">
              <div className="Modal--info-column">
                <div className="Modal--info-column-header">Applicant Type</div>
                <div className="Modal--info-column-detail">{programDetails.application_type}</div>
              </div>
              <div className="Modal--info-column">
                <div className="Modal--info-column-header">Delivery Method</div>
                <div className="Modal--info-column-detail">{programDetails.delivery}</div>
              </div>
              <div className="Modal--info-column">
                <div className="Modal--info-column-header">How To Apply?</div>
                <div className="Modal--info-column-detail">
                  {showHowApply(programDetails.sophas_status)}
                </div>
              </div>
              <div className="Modal--info-column">
                <div className="Modal--info-column-header">GRE Requirement</div>
                <div className="Modal--info-column-detail">
                  {showGreRequirement(programDetails.gre_requirement)}
                </div>
              </div>
            </div>
            {sophasState && (
              <a
                className="Program--apply-button"
                target="_blank"
                rel="noopener noreferrer"
                href={buildLink(
                  programDetails.sophas_status,
                  programDetails.organization_website,
                  programDetails,
                  programDetails.organization_website
                )}
              >
                {showButtonText(programDetails.sophas_status)}
              </a>
            )}

            <a
              className="Program--learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
              href={programDetails.organization_website}
            >
              Learn More
            </a>

            <div dangerouslySetInnerHTML={{ __html: programDetails.description }}></div>

            <div className="Modal--stem-disclaimer">
              {programDetails.stem_program == 1 &&
                `* Please contact the institution registrar's office to confirm the program's STEM Designation`}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Program;
