import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faExternalLink from '@fortawesome/fontawesome-pro-light/faExternalLink';
import Degree from '../Degree/Degree';
import './DegreeList.css';

const DegreeList = ({
  degrees,
  handleDegreeClick,
  handleTermClick,
  organizationId,
  organizationIndex,
  website
}) => (
  <div className="DegreeList--container">
    {!degrees.length && (
      <div className="DegreeList--no-offerings">
        <div className="Degree--container">
          <div className="Degree--bar">
            <div className="Degree--name">
              <a href={website} target="_blank" rel="noopener noreferrer">
                See member website for offerings <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </div>
          </div>
        </div>
      </div>
    )}

    {degrees.map((degree, index) => (
      <Degree
        key={degree.id || index}
        id={degree.id}
        index={index}
        abbreviation={degree.level}
        // concentration={degree.concentration}
        name={degree.name}
        terms={degree.terms}
        termsShown={degree.termsShown}
        handleDegreeClick={handleDegreeClick}
        handleTermClick={handleTermClick}
        organizationId={organizationId}
        loading={degree.loading}
        organizationIndex={organizationIndex}
      />
    ))}
  </div>
);

export default DegreeList;
