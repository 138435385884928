/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faSpinner from '@fortawesome/fontawesome-pro-light/faSpinner';
import faInfoCircle from '@fortawesome/fontawesome-pro-light/faInfoCircle';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import ReactTooltip from 'react-tooltip';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import FilterOptionList from '../FilterOptionList/FilterOptionList';
import './SearchForm.css';
import MobileFilterOptions from '../MobileFilterOptions/MobileFilterOptions';

const SearchForm = ({
  areasOfStudy,
  filterCephAccredited,
  filterCephApplicant,
  filterOptions,
  handleAreasOfStudyCheckBoxClick,
  handleClearFiltersClick,
  handleFilterButtonClick,
  handleFilterCheck,
  handleApplicantTypeChange,
  handleInstitutionCheckBoxClick,
  handleLocationDistanceChange,
  handleLocationKeywordsChange,
  handleLocationStateChange,
  handleLocationCountryChange,
  handleLocationMethodChange,
  handleProgramAreaKeywordsChange,
  handleSearchSubmit,
  institutionHeaderCount,
  institutions,
  locationDistance,
  locationKeywords,
  locationState,
  locationCountry,
  locationMethod,
  programAreaKeywords,
  searching,
  stateOptions,
  countryOptions,
  selectedApplicantType
}) => {
  let countries = [];
  let states = stateOptions;

  countryOptions.forEach((country) => {
    countries.push({ value: country, label: country });
  });

  // stateOptions.forEach((state) => {
  //   states.push({ value: state, label: state });
  // });
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
  };

  const customCountryStyles = {
    control: (provided) => ({
      ...provided,
      height: '35px',
      minHeight: '29px',
      borderRadius: '0px',
      width: '250px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
      display: 'flex',
      alignItems: 'center',
      minHeight: '29px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      minHeight: '29px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '29px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0',
    }),
    placeholder: (provided) => ({
      ...provided,
      transform: 'translateY(-3px)',
    }),
  };
  return (
    <div className="form-section">
      <form id="search-form" onSubmit={handleSearchSubmit}>
        <div className="fields-container clearfix">
          <div className="column area-of-study-column">
            <div className="area-of-study-wrapper">
              <p className="fieldset-label">Area of Study</p>
              <div className="fieldset-wrapper">
                <fieldset>
                  {areasOfStudy.map((p, index) => (
                    <CheckboxGroup
                      key={p.name}
                      name={p.name}
                      index={index}
                      checkHandler={handleAreasOfStudyCheckBoxClick}
                      checked={p.checked}
                    />
                  ))}
                </fieldset>
              </div>
              <div className="keyword-box">
                <p className="keyword-or">OR</p>
                <p className="keyword-label">Keyword</p>
                <input
                  type="text"
                  value={programAreaKeywords}
                  onChange={handleProgramAreaKeywordsChange}
                  placeholder="e.g. Diabetes, Chronic Disease"
                />
                <div className="keyword-location-fields keyword-location-mobile">
                  <div className="location-radio-buttons">
                    <form>
                      <label>
                        <input
                          type="radio"
                          value="city/zip"
                          checked={locationMethod === 'city/zip'}
                          onChange={handleLocationMethodChange}
                        />
                        City/Zip Code
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="state/province"
                          checked={locationMethod === 'state/province'}
                          onChange={handleLocationMethodChange}
                        ></input>
                        State/Province
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="country"
                          checked={locationMethod === 'country'}
                          onChange={handleLocationMethodChange}
                        ></input>
                        Country
                      </label>
                    </form>
                  </div>
                  {/* Conditionally render location fields based on above radio buttons */}
                  {locationMethod === 'city/zip' && (
                    <div className="keyword-location-fields city">
                      <input
                        type="text"
                        value={locationKeywords}
                        onChange={handleLocationKeywordsChange}
                        placeholder="e.g. Washington, DC"
                      />
                      <Select
                        clearable={false}
                        name="locationDistance"
                        value={locationDistance}
                        onChange={handleLocationDistanceChange}
                        options={[
                          { value: 25, label: '25 Mi' },
                          { value: 100, label: '100 Mi' },
                          { value: 250, label: '250 Mi' },
                          { value: 1000, label: '500+ Mi' },
                        ]}
                      />
                      <p className="search-filter-label-mobile">
                        Adjust the search radius as needed.
                      </p>
                    </div>
                  )}
                  {locationMethod === 'state/province' && (
                    <div className="keyword-location-fields state">
                      <Select
                        clearable={false}
                        name="State/Province"
                        value={locationState}
                        onChange={handleLocationStateChange}
                        placeholder="Select State/Province"
                        options={states}
                        // isOptionDisabled={(option) => option.isDisabled}
                        isOptionDisabled={(option) => option.isDisabled === true}
                      />
                      <p className="search-filter-label-mobile">
                        This list only contains locations with participating institutions.
                      </p>
                    </div>
                  )}
                  {locationMethod === 'country' && (
                    <div className="keyword-location-fields country">
                      <Select
                        clearable={false}
                        name="Country"
                        value={locationCountry}
                        onChange={handleLocationCountryChange}
                        placeholder="Select Country"
                        options={countries}
                      />
                      <p className="search-filter-label-mobile">
                        This list only contains locations with participating institutions
                      </p>
                    </div>
                  )}
                  {/* <p className="keyword-label">City and State or Zip Code</p>
                  <input
                    type="text"
                    value={locationKeywords}
                    onChange={handleLocationKeywordsChange}
                    placeholder="e.g. Washington, DC"
                  />
                  <Select
                    clearable={false}
                    name="locationDistance"
                    value={locationDistance}
                    onChange={handleLocationDistanceChange}
                    options={[
                      { value: 50, label: '50 Mi' },
                      { value: 100, label: '100 Mi' },
                      { value: 250, label: '250 Mi' },
                    ]}
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="column institution-column">
            <div className="institution-wrapper">
              <p className="fieldset-label">Institution</p>
              <div className="fieldset-wrapper">
                <fieldset>
                  {institutions.map((p, index) => (
                    <CheckboxGroup
                      accredited={p.accredited}
                      key={p.name}
                      name={p.name}
                      index={index}
                      checkHandler={handleInstitutionCheckBoxClick}
                      checked={p.checked}
                      justify={!!p.justify}
                      filterCephAccredited={filterCephAccredited}
                      filterCephApplicant={filterCephApplicant}
                      header={p.header}
                      headerCount={institutionHeaderCount}
                    />
                  ))}
                </fieldset>
              </div>
              <div className="keyword-box">
                <p className="keyword-or">OR</p>
                <p className="keyword-label">
                  <div className="location-radio-buttons">
                    <form>
                      <label>
                        <input
                          type="radio"
                          value="city/zip"
                          checked={locationMethod === 'city/zip'}
                          onChange={handleLocationMethodChange}
                        />
                        City/Zip Code
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="state/province"
                          checked={locationMethod === 'state/province'}
                          onChange={handleLocationMethodChange}
                        ></input>
                        State/Province
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="country"
                          checked={locationMethod === 'country'}
                          onChange={handleLocationMethodChange}
                        ></input>
                        Country
                      </label>
                    </form>
                  </div>
                  {/* City and State or Zip Code &nbsp; */}
                  {/* <a data-tip="When searching a large state, <br />select the largest radius (500+ miles)">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </a>
                <ReactTooltip multiline event="click" clickable effect="solid" type="light" /> */}
                </p>

                {/* Conditionally render location fields based on above radio buttons */}
                {locationMethod === 'city/zip' && (
                  <div className="keyword-location-fields city">
                    <input
                      type="text"
                      value={locationKeywords}
                      onChange={handleLocationKeywordsChange}
                      placeholder="e.g. Washington, DC"
                    />
                    <Select
                      clearable={false}
                      name="locationDistance"
                      className="locationDistance--select"
                      isSearchable={false}
                      value={{ value: locationDistance, label: `${locationDistance} Mi` }}
                      onChange={handleLocationDistanceChange}
                      options={[
                        { value: 25, label: '25 Mi' },
                        { value: 100, label: '100 Mi' },
                        { value: 250, label: '250 Mi' },
                        { value: 1000, label: '500+ Mi' },
                      ]}
                    />
                    <div>
                      <p className="search-filter-label">Adjust the search radius as needed.</p>
                    </div>
                  </div>
                )}

                {locationMethod === 'state/province' && (
                  <div className="keyword-location-fields state">
                    <Select
                      clearable={false}
                      name="State/Province"
                      value={locationState ? { value: locationState, label: locationState } : null}
                      styles={customCountryStyles}
                      onChange={handleLocationStateChange}
                      placeholder="Select State/Province"
                      options={states}
                      isOptionDisabled={(option) => option.isDisabled}
                    // isOptionDisabled={(option) => option.isDisabled === true}
                    />

                    {/* <Select
                      clearable={false}
                      name="State/Province"
                      value={locationState}
                      onChange={handleLocationStateChange}
                      placeholder="Select State/Province"
                      options={states}
                    /> */}
                    <div>
                      <p className="search-filter-label">
                        This list only contains locations with participating institutions
                      </p>
                    </div>
                  </div>
                )}
                {locationMethod === 'country' && (
                  <div className="keyword-location-fields country">
                    <Select
                      clearable={false}
                      name="Country"
                      className="locationCountry--select"
                      value={locationCountry ? { value: locationCountry, label: locationCountry } : null}
                      onChange={handleLocationCountryChange}
                      placeholder="Select Country"
                      options={countries}
                      styles={customCountryStyles}
                    />
                    <p className="search-filter-label">
                      This list only contains locations with participating institutions
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <hr className="fields-hr"></hr>

        <FilterOptionList
          filterOptions={filterOptions}
          filterButtonClickHandler={handleFilterButtonClick}
          filterCheckHandler={handleFilterCheck}
          handleApplicantTypeChange={handleApplicantTypeChange}
          selectedApplicantType={selectedApplicantType}
        />
        <MobileFilterOptions
          filterOptions={filterOptions}
          filterCheckHandler={handleFilterCheck}
          institutions={institutions}
          handleInstitutionCheckBoxClick={handleInstitutionCheckBoxClick}
          filterCephAccredited={filterCephAccredited}
          filterCephApplicant={filterCephApplicant}
          headerCount={institutionHeaderCount}
        />
        <button type="submit" value="Search" className="search-btn" disabled={searching}>
          {searching ? <FontAwesomeIcon className="fa-spin" icon={faSpinner} /> : 'Search'}
        </button>
        <div className="clear-filters-container">
          <button onClick={handleClearFiltersClick} className="clear-filters-link">
            [Clear Filters]
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
