import React, { useState } from 'react';
import sumBy from 'lodash/sumBy';
import './FilterOptionList.css';
import FilterOptionButton from '../FilterOptionButton/FilterOptionButton';
import FilterOptionChecks from '../FilterOptionChecks/FilterOptionChecks';

function calculateCount(filters) {
  return sumBy(filters, f => (f.checked === true ? 1 : 0));
}

const FilterOptionList = ({ filterOptions, filterButtonClickHandler, filterCheckHandler, handleApplicantTypeChange, selectedApplicantType }) => {
  const [activeTab, setActiveTab] = useState("Delivery Method")

  return (
    <div className="FilterOptionList--container" id="FilterOptionList--container">
      <ul className="FilterOptionList--buttons">
        {filterOptions.map((filterOption, index) => (
          <FilterOptionButton
            key={index}
            index={index}
            clickHandler={filterButtonClickHandler}
            name={filterOption.name}
            count={calculateCount(filterOption.filters)}
            active={filterOption.active}
            setActiveTab={setActiveTab}
          />
        ))}
      </ul>

      <div className="FilterOptionList--checkbox-sections">
        {filterOptions.map((filterOption, index) => (
          <FilterOptionChecks
            filterCheckHandler={filterCheckHandler}
            handleApplicantTypeChange={handleApplicantTypeChange}
            key={index}
            index={index}
            active={filterOption.active}
            filters={filterOption.filters}
            activeTab={activeTab}
            selectedApplicantType={selectedApplicantType}
          />
        ))}
      </div>
    </div>
  )
};

export default FilterOptionList;
