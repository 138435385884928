import React from 'react';
import './CheckboxGroup.css';

const hideGroup = function hideGroup(
  filterCephAccredited,
  filterCephApplicant,
  accredited,
  header
) {
  if (!header && ((filterCephAccredited && !accredited) || (filterCephApplicant && accredited))) {
    return true;
  }
  return false;
};

const showName = function showName(header, headerCount, name) {
  let newName = name;
  if (header) {
    switch (name) {
      case 'All':
        newName = `${name} (${headerCount[0]})`;
        break;
      case 'CEPH Accredited':
        newName = `${name} (${headerCount[1]})`;
        break;
      case 'CEPH Applicant':
        newName = `${name} (${headerCount[2]})`;
        break;
      default:
        newName = name;
    }
  }
  return newName;
};

const CheckboxGroup = ({
  name,
  checkHandler,
  accredited,
  header,
  checked,
  justify,
  filterCephAccredited,
  filterCephApplicant,
  headerCount,
  index
}) => (
  <div
    style={
      hideGroup(filterCephAccredited, filterCephApplicant, accredited, header)
        ? { display: 'none' }
        : {}
    }
    className={justify ? 'checkbox-group justify' : 'checkbox-group'}
  >
    <input
      onChange={e => checkHandler(index, e)}
      checked={checked}
      type="checkbox"
      id={name}
      name={name}
      value={name}
    />
    <label htmlFor={name}>{showName(header, headerCount, name)}</label>
  </div>
);

export default CheckboxGroup;
