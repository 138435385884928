import React from 'react';
import './SubHeader.css';

const SubHeader = () => (
  <div className="SubHeader--container">
    {/* <p className="SubHeader--text">
      Select areas of study that match your interests and refine the results by adding additional
      search criteria.
    </p> */}
  </div>
);

export default SubHeader;
