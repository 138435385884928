import React from 'react';
import './FilterOptionButton.css';

const FilterOptionButton = ({ index, name, count, clickHandler, active, setActiveTab }) => {
  const handleButtonClick = (index, e) => {
    clickHandler(index, e)
    setActiveTab(name);
  };

  return (
    <li className={`FilterOptionButton--container ${active ? 'active' : ''}`}>
      <button key={name} className="FilterOptionButton--button" onClick={e => handleButtonClick(index, e)}>
        {name} {count > 0 && <span className="FilterOptionButton--count">({count})</span>}
      </button>
    </li>
  )
}

export default FilterOptionButton;
