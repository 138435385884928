import React from 'react';
import sumBy from 'lodash/sumBy';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
// import 'react-accessible-accordion/dist/minimal-example.css';
// import 'react-accessible-accordion/dist/fancy-example.css';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import FilterOptionChecks from '../FilterOptionChecks/FilterOptionChecks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faAngleDown from '@fortawesome/fontawesome-pro-light/faAngleDown';
import './MobileFilterOptions.css';

function calculateCount(filters) {
  return sumBy(filters, f => (f.checked === true ? 1 : 0));
}

const calculateInstitutionCount = (institutions) => {
  const institutionsWithoutHeaders = institutions.slice(3);
  if (institutionsWithoutHeaders.length > 0) {
    return sumBy(institutionsWithoutHeaders, f => (f.checked === true ? 1 : 0));
  }
  return 0;

}

const MobileFilterOptions = ({ filterOptions, filterCheckHandler, institutions, handleInstitutionCheckBoxClick, filterCephAccredited, filterCephApplicant, headerCount }) => (
  // <Accordion>
  //   <AccordionItem key="test">
  //     <AccordionItemTitle>
  //       <h3>Institution{' '}
  //         {calculateInstitutionCount(institutions) > 0 && <span className="FilterOptionButton--count">({calculateInstitutionCount(institutions)})</span>}
  //         <div className="accordion__chevron">
  //           <FontAwesomeIcon icon={faAngleDown} />
  //         </div>
  //       </h3>
  //     </AccordionItemTitle>
  //     <AccordionItemBody>
  //       <fieldset>
  //         {institutions.map((p, index) => (
  //           <CheckboxGroup
  //             accredited={p.accredited}
  //             key={p.name}
  //             name={p.name}
  //             index={index}
  //             checkHandler={handleInstitutionCheckBoxClick}
  //             checked={p.checked}
  //             justify={!!p.justify}
  //             filterCephAccredited={filterCephAccredited}
  //             filterCephApplicant={filterCephApplicant}
  //             header={p.header}
  //             headerCount={headerCount}
  //           />
  //         ))}
  //       </fieldset>
  //     </AccordionItemBody>

  //   </AccordionItem>
  //   {filterOptions.map((filterOption, index) => (
  //     <AccordionItem key={filterOption.name}>
  //       <AccordionItemTitle>
  //         <h3>
  //           {filterOption.name}{' '}
  //           {calculateCount(filterOption.filters) > 0 && <span className="FilterOptionButton--count">({calculateCount(filterOption.filters)})</span>}
  //           <div className="accordion__chevron">
  //             <FontAwesomeIcon icon={faAngleDown} />
  //           </div>
  //         </h3>
  //       </AccordionItemTitle>
  //       <AccordionItemBody>
  //         <FilterOptionChecks
  //           filterCheckHandler={filterCheckHandler}
  //           key={index}
  //           index={index}
  //           active
  //           filters={filterOption.filters}
  //         />
  //       </AccordionItemBody>
  //     </AccordionItem>
  //   ))}
  // </Accordion>
  <Accordion>
    <AccordionItem key="test">
      <AccordionItemHeading>
        <AccordionItemButton>
          <h3>Institution{' '}
            {calculateInstitutionCount(institutions) > 0 && <span className="FilterOptionButton--count">({calculateInstitutionCount(institutions)})</span>}
            <div className="accordion__chevron">
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </h3>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <fieldset>
          {institutions.map((p, index) => (
            <CheckboxGroup
              accredited={p.accredited}
              key={p.name}
              name={p.name}
              index={index}
              checkHandler={handleInstitutionCheckBoxClick}
              checked={p.checked}
              justify={!!p.justify}
              filterCephAccredited={filterCephAccredited}
              filterCephApplicant={filterCephApplicant}
              header={p.header}
              headerCount={headerCount}
            />
          ))}
        </fieldset>
      </AccordionItemPanel>

    </AccordionItem>
    {filterOptions.map((filterOption, index) => (
      <AccordionItem key={filterOption.name}>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3>
              {filterOption.name}{' '}
              {calculateCount(filterOption.filters) > 0 && <span className="FilterOptionButton--count">({calculateCount(filterOption.filters)})</span>}
              <div className="accordion__chevron">
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </h3>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FilterOptionChecks
            filterCheckHandler={filterCheckHandler}
            key={index}
            index={index}
            active
            filters={filterOption.filters}
          />
        </AccordionItemPanel>
      </AccordionItem>
    ))}
  </Accordion>
);

export default MobileFilterOptions;
