import { divide } from 'lodash';
import React from 'react';
import './Jumbotron.css';
import { apfImage } from '../../assets/images';

const Jumbotron = (props) => (
  <div className="jumbotron">
    <div className="container">
      <h2 className="sectionTitle">Find your best fit in public health</h2>
      <hr className="orange-underline" />
      <div className="row">
        <div className="column-12 column-5">
          <img
            className="jumbo-img"
            src={apfImage}
          />
        </div>

        <div className="column-12 column-6">
          <div>
            <p className="jumbo-text">
              The Academic Program Finder is designed to meet your unique needs and interests in the
              field of public health. Our comprehensive tool enables you to explore the variety of
              academic programs available, refine your search with ease, and uncover the ideal
              program that aligns with your goals, whether you have a specific area of study in mind
              or are just starting your exploration.
            </p>

            <p className="ugn-segment">
              If you're looking for a bachelor's program, explore the <a href='https://aspph.org/membership/undergraduate-network-directory/' target="_blank" rel="noopener noreferrer">Undergraduate Network Directory</a>, to access a list of schools offering undergraduate degrees in public health.
            </p>
          </div>

          <div className="popular-searches-wrapper">
            <h4 id="popular-searches">Popular Searches</h4>
            <ul>
              <li>
                <div onClick={props.handleEnvironmentalSearch}>
                  Environmental Health, Environmental Justice, Climate Change, and Disaster
                  Preparedness
                </div>
              </li>
              <li>
                <div onClick={props.handlePreventativeSearch}>Preventative Health</div>
              </li>
              <li>
                <div onClick={props.handleDrPhd}>DrPH or PhD</div>
              </li>
              <li>
                <li>
                  <div onClick={props.handleDualDegrees}>Dual Degrees</div>
                </li>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div >
);

export default Jumbotron;
