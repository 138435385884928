import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faFlickr,
} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => (
  <footer className="Footer">
    <div className="container">
      <div className="Footer--aspphinfo">
        <div className="Footer--logo-aspph">
          <a href="https://aspph.org" target="_blank" rel="noopener noreferrer">
            <img src="https://aspphwebassets.s3.amazonaws.com/aspph_logos/v2/web/ASPPH-logo-white.png" />
          </a>
        </div>
        {/* <div className="Footer--links">
          <ul>
            <li><a href="#">Support ASPPH</a></li>
            <li><a href="#">Login</a></li>
            <li><a href="#">Contact Us</a></li>
          </ul>
        </div>
        <div className="Footer--privacy-policy"><a href="#">Privacy Policy</a></div> */}
        <div className="Footer--copyright">
          © {new Date().getFullYear()} ASPPH. All rights reserved.
        </div>
      </div>
      <div className="Footer--social">
        <div className="Footer--social-links">
          <ul>
            <li>
              <a href="https://www.facebook.com/ASPPH" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/aspphorg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            {/* <li><a href="https://twitter.com/ASPPHtweets" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></li> */}
            <li>
              <a
                href="https://www.linkedin.com/company/association-of-schools-and-programs-of-public-health"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </li>
            <li>
              <a
                href="https://www.flickr.com/photos/146804962@N03/albums"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFlickr} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="Footer--copyright-mobile">
        © {new Date().getFullYear()} ASPPH, All rights reserved
      </div>
    </div>
  </footer>
);

export default Footer;
