import React from 'react';
import AppContainer from './containers/AppContainer';
import Program from './components/Program/Program';
// import { Routes, Route } from 'react-router-dom';

const App = () => (
  <div className="App">
    {/* <Routes>
      <Route path="/" element={<AppContainer />} />
      <Route path="/programs" element={<Program />} />
    </Routes> */}
    <AppContainer />
  </div>
);

export default App;
