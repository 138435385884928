import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faSpinner from '@fortawesome/fontawesome-pro-light/faSpinner';
import './Spinner.css';

const Spinner = () => (
  <div className="Spinner--container">
    <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
  </div>
);

export default Spinner;
