import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <header className="Header">
      <div className="Header--container container">
        <a href="https://www.aspph.org">
          <img
            src="https://aspphwebassets.s3.amazonaws.com/aspph_org/ASPPH-color.png"
            className="aspph-logo"
            alt="ASPPH Logo"
          />
        </a>
        <a href="https://www.aspph.org/student-journey" target="_blank">
          <h4>Student Journey</h4>
        </a>

        <a
          href="https://aspph.org/student-journey/#section1"
          target="_blank"
          className="students-nav-item"
        >
          Explore Public Health
        </a>
        <a href="https://aspph.org/student-journey/#section2" className="students-nav-item" target="_blank">
          Decide to Study Public Health
        </a>
        <a href="https://aspph.org/student-journey/#section3" target="_blank" className="students-nav-item">
          Apply to Graduate School
        </a>
        <a
          href="https://aspph.org/students-alumni/selphprogram/"
          className="students-nav-item subnav-toggle"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {' > >'}
        </a>
        <ul
          className="subnav-hover-div"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ display: isHovered ? 'block' : 'none' }}
        >
          <li>
            <a href="https://aspph.org/student-journey/financing-your-degree/" target="_blank">Finance Your Degree</a>
          </li>
          <li>
            <a href="https://aspph.org/student-journey/#section5" target="_blank">Life-Long Learning</a>
          </li>
          <li>
            <a href="https://aspph.org/student-journey/#section6" target="_blank">
              Find Your Career
            </a>
          </li>
          <li>
            <a href="https://aspph.org/student-journey/get-involved/">Get Involved</a>
          </li>
        </ul>

        {/* <h1><span style={{ color: "#00335b" }}>ASPPH</span> ACADEMIC PROGRAM FINDER</h1> */}
      </div>
    </header>
  );
};

export default Header;
