import Select from 'react-select'
import React from 'react';
import './ApplicantType.css';

const ApplicantType = ({ selectedApplicantType, handleApplicantTypeChange }) => {
  const options = [
    { value: 'all', label: 'All applicants' },
    { value: 'us', label: 'US applicants' },
    { value: 'international', label: 'International applicants' },
    { value: 'priority', label: 'Priority' }
  ];

  const applicantValue = selectedApplicantType || null;

  const handleChange = (selectedOption) => {
    handleApplicantTypeChange(selectedOption);
  };

  return (
    <div className="SelectBar--select-container">
      <hr></hr>
      <label className="SelectBar--label" htmlFor="applicant-type">Applicant Type:</label>
      <Select
        clearable={false}
        name="applicant-type"
        placeholder="Select an option" //
        value={applicantValue}
        onChange={handleChange}
        options={options}
        className="FilterOptionChecks--select"
      />
    </div>
  )
};

export default ApplicantType;
