import React from 'react';
import Term from '../Term/Term';
import './TermList.css';

const TermList = ({ terms, handleTermClick, degreeIndex, organizationIndex }) => (
  <div className="TermList--container">
    <table className="TermList--table">
      <thead>
        <tr>
          <th>Term</th>
          <th width="175">Concentration</th>
          <th>Delivery Method</th>
          <th>Applicant Type</th>
          <th>Deadline</th>
          <th>SOPHAS</th>
        </tr>
      </thead>
      <tbody>
        {terms.map((term, index) => (
          <Term
            key={term.id}
            index={index}
            concentration={term.concentration}
            startTerm={term.start_term}
            startYear={term.start_year}
            type={term.type}
            delivery={term.delivery}
            applicationType={term.application_type}
            deadline={term.deadline_display}
            sophasStatus={term.sophas_status}
            handleTermClick={handleTermClick}
            degreeIndex={degreeIndex}
            organizationIndex={organizationIndex}
          />
        ))}
      </tbody>
    </table>
  </div>
);

export default TermList;
