import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faUserGraduate from '@fortawesome/fontawesome-pro-light/faUserGraduate';
import faCalendarAlt from '@fortawesome/fontawesome-pro-light/faCalendarAlt';
import faBookOpen from '@fortawesome/fontawesome-pro-light/faBookOpen';
import ReactGA from "react-ga4";
import './Sidebar.css';
import { applyIcon, careerIcon, decideIcon, engageIcon, financeIcon, learnIcon, exploreIcon } from "../../assets/images";

const Sidebar = () => {
  let phJourneyBlocks = [
    {
      title: 'Explore Public Health Pathways',
      link: 'https://aspph.org/student-journey/#section1',
      image: exploreIcon
    },
    {
      title: 'Discover Your Future at Our Student Fairs!',
      link: 'https://aspph.org/events/category/student-recruitment/',
      image: decideIcon
    },
    {
      title: 'Step Into Your Future:',
      subheader: 'Apply to Graduate School Today',
      link: 'https://sophas.aspph.org',
      image: applyIcon,
    },
    {
      title: 'Invest in Yourself:',
      subheader: 'Financing Your Public Health Degree',
      link: 'https://aspph.org/student-journey/financing-your-degree/',
      image: financeIcon,
    },
    {
      title: 'Embrace Life-Long Learning:',
      subheader: 'Your Journey Starts Here',
      link: 'https://aspph.org/student-journey/#section5',
      image: learnIcon,
    },
    {
      title: 'Shape Your Path:',
      subheader: 'Find Your Public Health Career',
      link: 'https://publichealthjobs.aspph.org/',
      image: careerIcon,
    },
    {
      title: 'Make an Impact:',
      subheader: 'Get Involved in Public Health Today',
      link: 'https://aspph.org/student-journey/get-involved/',
      image: engageIcon,
    },
  ];

  const handleLinkClick = (platform) => {
    ReactGA.event({
      category: 'Sidebar Link Click',
      action: 'Click',
      label: platform,
    });
  };


  return (
    <div className="Sidebar">
      <section className="Sidebar--logo-highlights Sidebar--latest-news">
        <div className="iframe-wrapper">
          <iframe
            id="myFrame"
            src="https://pardot.aspph.org/l/497911/2023-08-09/w431l"
            height="400"
            type="text/html"
            frameborder="0"
            overflow="hidden"
            allowTransparency="true"
          ></iframe>
        </div>
      </section >

      <section className="Sidebar--student-journey">
        <h3 className="Sidebar--title">Embark on Your Public Health Journey Today!</h3>

        <div class="vertical-line">
          {phJourneyBlocks.map((block, index) => (
            <div className="timeline-row" key={block.id || index}>
              <img src={block.image}></img>
              <div className="timeline-text">
                <a
                  href={block.link}
                  target="_blank"
                  onClick={() => handleLinkClick(block.title)}
                >
                  <p>{block.title}</p>
                  {block.subheader && (
                    <span className="Sidebar--subheader">{block.subheader}</span>
                  )}
                </a>
              </div>
              <hr />
            </div>
          ))}

          {/* <div className="timeline-row">
            <img src="http://aspph2-wp-production.s3.us-east-1.amazonaws.com/wp-content/uploads/2022/03/Icons-17.png"></img>
            <div className="timeline-text">Explore Public Health</div>
          </div>
          <div className="timeline-row">
            <img src="http://aspph2-wp-production.s3.us-east-1.amazonaws.com/wp-content/uploads/2022/03/Icons-17.png"></img>
            <div className="timeline-text">Explore Public Health</div>
          </div>
          <div className="timeline-row">
            <img src="http://aspph2-wp-production.s3.us-east-1.amazonaws.com/wp-content/uploads/2022/03/Icons-17.png"></img>
            <div className="timeline-text">Explore Public Health</div>
          </div>
          <div className="timeline-row">
            <img src="http://aspph2-wp-production.s3.us-east-1.amazonaws.com/wp-content/uploads/2022/03/Icons-17.png"></img>
            <div className="timeline-text">Explore Public Health</div>
          </div>
          <div className="timeline-row">
            <img src="http://aspph2-wp-production.s3.us-east-1.amazonaws.com/wp-content/uploads/2022/03/Icons-17.png"></img>
            <div className="timeline-text">Explore Public Health</div>
          </div>
          <div className="timeline-row">
            <img src="http://aspph2-wp-production.s3.us-east-1.amazonaws.com/wp-content/uploads/2022/03/Icons-17.png"></img>
            <div className="timeline-text">Explore Public Health</div>
          </div>
          <div className="timeline-row">
            <img src="http://aspph2-wp-production.s3.us-east-1.amazonaws.com/wp-content/uploads/2022/03/Icons-17.png"></img>
            <div className="timeline-text">Explore Public Health</div>
          </div> */}
        </div>
      </section>

      {/* <section className="Sidebar--highlights">
    </section> */}
    </div >
  );
};

export default Sidebar;
