import React from 'react';
import './Degree.css';
import TermList from '../TermList/TermList';
import Chevron from '../Chevron/Chevron';
import Spinner from '../Spinner/Spinner';

const Degree = ({
  id,
  name,
  abbreviation,
  concentration,
  terms,
  termsShown,
  handleDegreeClick,
  handleTermClick,
  organizationId,
  loading,
  organizationIndex,
  index
}) => (
  <div>
    <div className="Degree--container">
      <div className="Degree--bar" onClick={() => handleDegreeClick(index, organizationIndex)}>
        <div className="Degree--name">
          <a>{name}</a>
        </div>
        {loading && <Spinner />}
        {!loading && <Chevron status={termsShown ? 'open' : 'closed'} />}
      </div>
    </div>
    {termsShown && (
      <TermList
        terms={terms}
        degreeIndex={index}
        organizationIndex={organizationIndex}
        handleTermClick={handleTermClick}
      />
    )}
  </div>
);

export default Degree;
