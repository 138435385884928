/* global window */
import { countBy } from 'lodash';

const getAreasOfStudy = () => {
  try {
    return JSON.parse(window.localStorage.getItem('cachedAreasOfStudy'));
  } catch (error) {
    return null;
  }
};

const getInstitutions = () => {
  try {
    return JSON.parse(window.localStorage.getItem('cachedInstitutions'));
  } catch (error) {
    return null;
  }
};

const getHeaderCounts = () => {
  try {
    return JSON.parse(window.localStorage.getItem('cachedInstitutionHeaderCounts'));
  } catch (error) {
    return null;
  }
};

const getProgramInfo = () => {
  // alert('attempting to get info!');
  try {
    return JSON.parse(window.localStorage.getItem('cachedProgramDetails'));
  } catch (error) {
    alert('failed to get info');
    return null;
  }
};

const setAreasOfStudy = (areasOfStudy) => {
  try {
    window.localStorage.setItem('cachedAreasOfStudy', JSON.stringify(areasOfStudy));
  } catch (error) {
    console.error(error);
  }
};

const setInstitutions = (institutions) => {
  try {
    const cachceableFormInstitutions = institutions.map((institution, index) => {
      if (index > 2) {
        return {
          name: institution.name,
          location: institution.location,
          distance: institution.distance,
          website: institution.website,
          accredited: institution.accredited,
          programs: [],
          degrees: [],
          checked: false,
        };
      }
      return institution;
    });
    window.localStorage.setItem('cachedInstitutions', JSON.stringify(cachceableFormInstitutions));
  } catch (error) {
    console.error(error);
  }
};
const setHeaderCounts = (institutions) => {
  try {
    const sliced = institutions.slice(3);
    const total = sliced.length;
    const accredited = countBy(sliced, 'accredited');
    window.localStorage.setItem(
      'cachedInstitutionHeaderCounts',
      JSON.stringify([total, accredited.true || 0, accredited.false || 0])
    );
  } catch (error) {
    console.error(error);
  }
};

const setProgramInfo = (programDetails) => {
  try {
    window.localStorage.setItem('cachedProgramDetails', JSON.stringify(programDetails));
  } catch (error) {
    console.log(error);
  }
};

export default {
  getAreasOfStudy,
  getInstitutions,
  getHeaderCounts,
  getProgramInfo,
  setAreasOfStudy,
  setInstitutions,
  setHeaderCounts,
  setProgramInfo,
};
