import React from 'react';
import './Hero.css';

const scrollToSearch = (divID) => {
  const target = document.getElementById(divID);
  if (target) {
    const offsetTop = target.getBoundingClientRect().top;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    const finalScrollPosition = offsetTop + scrollPosition - 75;

    window.scrollTo({
      top: finalScrollPosition,
      behavior: 'smooth',
    });
  }
};
const Hero = ({ heroProps }) => (
  <div className="hero">
    <div className="container">
      <div class="breadcrumb-nav">
        {heroProps.links.map((link, i) => {
          return (
            <React.Fragment key={i}>
              <a href={link.url}>
                {i === 0 ? <i class="fas fa-home" aria-hidden="true"></i> : ''}
                <span class="breadcrumb-nav-item">{link.text}</span>
              </a>
              <span> {'>'} </span>
            </React.Fragment>
          );
        })}
      </div>
      <h2 className="hero-title">{heroProps.title}</h2>
      <p className="hero-text">{heroProps.text}</p>

      {heroProps.button && (
        <button id="explore-button" onClick={() => scrollToSearch('mainContent')}>
          {heroProps.button.text}
        </button>
      )}
    </div>
  </div>
);

export default Hero;
