import React from 'react';
import Select from 'react-select';
import numeral from 'numeral';
import pluralize from 'pluralize';
// import 'react-select/dist/react-select.css';
import './SortBar.css';

const SortBar = ({
  selectedSortPreference,
  handleSortPreferenceChange,
  locationSearched,
  programsCount,
  institutionsCount,
  searched,
}) => (
  <div className="SortBar--container clearfix">
    <div className="SortBar--search-results">
      {searched && programsCount > 0 && (
        <span>
          Search results: {numeral(institutionsCount).format('0,0')}{' '}
          {pluralize('institutions', institutionsCount)} and {numeral(programsCount).format('0,0')}{' '}
          {pluralize('programs', programsCount)}
        </span>
      )}
    </div>
    <div className="SortBar--select-container">
      <Select
        clearable={false}
        name="sort-preference"
        value={selectedSortPreference}
        onChange={handleSortPreferenceChange}
        options={[
          { value: 'asc', label: 'Institution(A-Z)' },
          { value: 'desc', label: 'Institution(Z-A)' }

          // { value: 'location', label: 'Location', disabled: !locationSearched },
        ]}
      />
    </div>
    <span className="SortBar--sort-label">SORT BY:</span>
  </div>
);

export default SortBar;
