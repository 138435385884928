import React from 'react';
import './FilterOptionChecks.css';
import ApplicantType from '../ApplicantType/ApplicantType';


const formatFilterName = (name) => {
  const [firstPart, ...rest] = name.split('-');
  return `<strong>${firstPart.trim()}</strong> - ${rest.join('-').trim()}`;
};

const FilterOptionChecks = ({ index, active, filters, filterCheckHandler, activeTab, handleApplicantTypeChange, selectedApplicantType }) => {

  return (
    < div className={`FilterOptionChecks--container ${active ? 'active' : ''}`}>
      <div className="FilterOptionChecks--wrapper">
        {filters.map((filter, filterIndex) => (
          <div key={filter.name} className="FilterOptionChecks--checkbox-group checkbox-group">
            <input
              onChange={(e) => filterCheckHandler(index, filterIndex, e)}
              type="checkbox"
              name={filter.name}
              checked={filter.checked}
              className="FilterOptionChecks--input"
            />
            <label
              dangerouslySetInnerHTML={{ __html: activeTab == "Degree" ? formatFilterName(filter.name) : filter.name }}
              className={filter.name === 'STEM Designated Programs' ? 'FilterOptionChecks--label stem-filter' : 'FilterOptionChecks--label'}
            />
          </div>
        ))}
      </div>
      {activeTab === "Additional Filters" &&
        <ApplicantType
          selectedApplicantType={selectedApplicantType}
          handleApplicantTypeChange={handleApplicantTypeChange}
        />
      }
    </div >
  )
};

export default FilterOptionChecks;
